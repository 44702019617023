/*
 *  Copyright (C) GridGain Systems. All Rights Reserved.
 *  _________        _____ __________________        _____
 *  __  ____/___________(_)______  /__  ____/______ ____(_)_______
 *  _  / __  __  ___/__  / _  __  / _  / __  _  __ `/__  / __  __ \
 *  / /_/ /  _  /    _  /  / /_/ /  / /_/ /  / /_/ / _  /  _  / / /
 *  \____/   /_/     /_/   \_,__/   \____/   \__,_/  /_/   /_/ /_/
 */

import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { handleLoading } from '@app/common/rxjs-operators/handle-loading';
import { extractError, isTruthy } from '@app/common/utils';
import { getAllRouteParams, waitForNavigation } from '@app/common/utils/router';
import { clusterById, clustersLoaded } from '@app/core/ngrx/app.selectors';
import { Clusters } from '@app/core/services';
import { Clusters3xApiService } from '@app/core/services/clusters-3x.service';
import { FrontendClusterUserId } from '@app/core/types/cluster-users';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigationAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { isCluster2x } from '@shared/domain/cluster';
import { ClusterId } from '@shared/types/cluster';
import { ActionName, ClusterActionCompletedResponse } from '@shared/types/cluster-actions';
import { isEqual } from 'lodash-es';
import md5 from 'md5';
import {
  EMPTY,
  Observable,
  catchError,
  combineLatest,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { Dialog } from '../constants';
import {
  addClusterUser,
  addClusterUserErr,
  addClusterUserOk,
  changeClusterUserPassword,
  changeClusterUserPasswordErr,
  changeClusterUserPasswordOk,
  clusterUsers,
  getClusterUsers,
  getClusterUsersErr,
  removeClusterUser,
  removeClusterUserErr,
  removeClusterUserOk,
} from './cluster-user-management.actions';

@Injectable({ providedIn: 'root' })
export class ClusterUserManagementEffects {
  private store = inject(Store);
  private actions$ = inject(Actions);
  private clusters2xApi = inject(Clusters);
  private clusters3xApi = inject(Clusters3xApiService);
  private router = inject(Router);
  private matDialog = inject(MatDialog);

  private is2x = (clusterId: ClusterId): boolean | null => {
    const clusterInfo = this.store.selectSignal(clusterById(clusterId))();
    if (!clusterInfo) return null;
    return isCluster2x(clusterInfo);
  };

  loading$ = createEffect(() =>
    this.actions$.pipe(
      handleLoading([
        { start: addClusterUser, end: [addClusterUserOk, addClusterUserErr] },
        { start: changeClusterUserPassword, end: [changeClusterUserPasswordOk, changeClusterUserPasswordErr] },
        { start: getClusterUsers, end: [clusterUsers, getClusterUsersErr] },
      ]),
    ),
  );

  // User management

  needClusterUsers$ = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(routerNavigationAction)),
      this.store.select(clustersLoaded).pipe(filter(isTruthy), take(1)),
    ]).pipe(
      map(
        ([a]) =>
          [
            a.payload.routerState.url.includes(`/credentials`),
            getAllRouteParams<{ contextActionClusterId: ClusterId }>(a.payload.routerState.root),
          ] as const,
      ),
      distinctUntilChanged((a, b) => isEqual(a, b)),
      filter(([routeMatches, { contextActionClusterId }]) => !!routeMatches && !!contextActionClusterId),
      switchMap(([, { contextActionClusterId }]) =>
        contextActionClusterId ? of(getClusterUsers({ clusterId: contextActionClusterId })) : EMPTY,
      ),
    ),
  );

  getClusterUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getClusterUsers),
      exhaustMap(({ clusterId }) => {
        const is2x = this.is2x(clusterId);
        if (is2x === null) return EMPTY;
        const request = is2x
          ? this.clusters2xApi.getUsers(clusterId).pipe(
              map((res) =>
                clusterUsers({
                  clusterId: clusterId,
                  users: res.map((name) => ({ id: md5(name) as FrontendClusterUserId, name })),
                }),
              ),
            )
          : this.clusters3xApi.getUsers(clusterId).pipe(
              map((res) =>
                clusterUsers({
                  clusterId: clusterId,
                  users: res.users.map((user) => ({ id: md5(user.login) as FrontendClusterUserId, name: user.login })),
                }),
              ),
            );
        return request.pipe(
          catchError((err) =>
            of(
              getClusterUsersErr({
                clusterId: clusterId,
                error: extractError(err, 'Failed to get cluster users'),
              }),
            ),
          ),
        );
      }),
    ),
  );

  closeClusterUsersDialogIfFailedToLoad$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getClusterUsersErr),
        tap(async () => {
          // The dialog may be still loading, need to wait for it to finish first
          await waitForNavigation(this.router);
          this.matDialog.getDialogById(Dialog.MANAGE_CLUSTER_USERS_ID)?.close();
        }),
      ),
    { dispatch: false },
  );

  reloadUsersOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addClusterUserOk, removeClusterUserOk, changeClusterUserPasswordOk),
      map((action) => getClusterUsers({ clusterId: action.clusterId })),
    ),
  );

  addClusterUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addClusterUser),
      exhaustMap((action) => {
        const is2x = this.is2x(action.clusterId);
        if (is2x === null) return EMPTY;
        const request: Observable<ClusterActionCompletedResponse<ActionName.ADD_CLUSTER_USER> | null> = is2x
          ? this.clusters2xApi.addUser(action.clusterId, action.user.name, action.user.password)
          : this.clusters3xApi.addUser(action.clusterId, { login: action.user.name, password: action.user.password });
        return request.pipe(
          map(() => addClusterUserOk({ clusterId: action.clusterId, name: action.user.name })),
          catchError((err) =>
            of(
              addClusterUserErr({
                error: extractError(err, 'Failed to add cluster user'),
                clusterId: action.clusterId,
                name: action.user.name,
              }),
            ),
          ),
        );
      }),
    ),
  );

  closeAddClusterUserDialogOnSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addClusterUserOk),
        tap(() => this.matDialog.getDialogById(Dialog.ADD_CLUSTER_USER_ID)?.close()),
      ),
    { dispatch: false },
  );

  changeClusterUserPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeClusterUserPassword),
      switchMap((action) => {
        const is2x = this.is2x(action.clusterId);
        if (is2x === null) return EMPTY;
        if (is2x) {
          return this.clusters2xApi.removeUser(action.clusterId, action.user.name).pipe(
            switchMap(() =>
              this.clusters2xApi.addUser(action.clusterId, action.user.name, action.user.password).pipe(
                map(() => changeClusterUserPasswordOk({ clusterId: action.clusterId, name: action.user.name })),
                catchError((err) =>
                  of(
                    changeClusterUserPasswordErr({
                      error: extractError(err, 'Failed to change user password'),
                      clusterId: action.clusterId,
                      name: action.user.name,
                    }),
                  ),
                ),
              ),
            ),
            catchError((err) =>
              of(
                changeClusterUserPasswordErr({
                  error: extractError(err, 'Failed to change user password'),
                  clusterId: action.clusterId,
                  name: action.user.name,
                }),
              ),
            ),
          );
        } else {
          return this.clusters3xApi.removeUser(action.clusterId, action.user.name).pipe(
            switchMap(() =>
              this.clusters3xApi
                .addUser(action.clusterId, { login: action.user.name, password: action.user.password })
                .pipe(
                  map(() => changeClusterUserPasswordOk({ clusterId: action.clusterId, name: action.user.name })),
                  catchError((err) =>
                    of(
                      changeClusterUserPasswordErr({
                        error: extractError(err, 'Failed to change user password'),
                        clusterId: action.clusterId,
                        name: action.user.name,
                      }),
                    ),
                  ),
                ),
            ),
            catchError((err) =>
              of(
                changeClusterUserPasswordErr({
                  error: extractError(err, 'Failed to change user password'),
                  clusterId: action.clusterId,
                  name: action.user.name,
                }),
              ),
            ),
          );
        }
      }),
    ),
  );

  closeChangeClusterUserPasswordDialogOnSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(changeClusterUserPasswordOk),
        tap(() => this.matDialog.getDialogById(Dialog.CHANGE_CLUSTER_USER_PASSWORD_ID)?.close()),
      ),
    { dispatch: false },
  );

  removeClusterUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeClusterUser),
      mergeMap((action) => {
        const is2x = this.is2x(action.clusterId);
        if (is2x === null) return EMPTY;
        const request: Observable<ClusterActionCompletedResponse<ActionName.REMOVE_CLUSTER_USER> | null> = is2x
          ? this.clusters2xApi.removeUser(action.clusterId, action.name)
          : this.clusters3xApi.removeUser(action.clusterId, action.name);
        return request.pipe(
          map(() => removeClusterUserOk({ clusterId: action.clusterId, name: action.name })),
          catchError((err) =>
            of(
              removeClusterUserErr({
                error: extractError(err, 'Failed to remove user'),
                clusterId: action.clusterId,
                name: action.name,
              }),
            ),
          ),
        );
      }),
    ),
  );
}
